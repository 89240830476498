/* Ensure the desktop layout is full-screen and centered */
.desktop {
    background-image: url('../../public/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.taskbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0 0 30px 30px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}

.taskbar-content {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.ico {
    width: 80px;
    height: 40px;
    background-image: url('../../public/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 20px;
}

.time, .battery-percentage {
    font-size: 16px;
    color: white;
    margin-right: 20px;
}

.battery {
    display: flex;
    align-items: center;
    color: white; /* White text color */
    margin-right: 20px; /* Ensure it has space from other elements */
}

.battery-icon {
    width: 24px; /* Battery width */
    height: 12px; /* Battery height */
    border: 2px solid #fff; /* White outline */
    border-radius: 4px; /* Rounded corners */
    position: relative;
    background-color: #00ff00; /* Green color indicating full battery */
    margin-right: 5px; /* Space between battery and percentage */
}

.battery-icon::after {
    content: '';
    position: absolute;
    top: 3px; /* Slightly lower to be centered vertically */
    right: -6px; /* Positioned on the right to form the battery tip */
    width: 4px; /* Width of the battery tip */
    height: 6px; /* Height of the battery tip */
    background-color: #fff; /* White color for the battery tip */
    border-radius: 1px; /* Slightly rounded corners for the tip */
}

.desktop-icons {
    position: absolute;
    top: 80px; /* Position the icons below the taskbar */
    left: 20px; /* Align the icons to the left side with some margin */
    display: flex;
    flex-direction: column; /* Align the icons vertically */
    gap: 20px; /* Add space between icons */
    align-items: flex-start; /* Ensure the icons are aligned to the left */
    justify-content: flex-start; /* Make sure they don't get centered */
}

.desktop-icons .icon {
    width: 100px;
    height: 100px;
    background: rgba(221, 221, 221, 0.8); /* Lighter grey with 80% opacity */
    border-radius: 10px;
    display: flex;
    flex-direction: column; /* Stack the image and label vertically */
    align-items: center; /* Center the image and label within each icon */
    justify-content: center;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.icon {
    width: 100px;  /* Adjust width as necessary */
    height: auto;  /* Let height adjust naturally */
    background: none; /* Remove the background color */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon-image {
    max-width: 85%;  /* Make sure image fits within the icon container */
    height: auto;     /* Keep the image's aspect ratio */
}

.movable-window {
    position: absolute;
    width: 300px;
    height: 200px;
    background-color: #f0f0f0; /* Background for movable windows */
    border: 1px solid #ccc; /* Border for movable windows */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    display: flex; /* Flex for content arrangement */
    flex-direction: column; /* Stack title and content vertically */
    overflow: hidden; /* Hide overflow */
}

.window-titlebar {
    background-color: #0078d4; /* Titlebar color */
    padding: 10px; /* Padding inside titlebar */
    color: white; /* Titlebar text color */
    font-weight: bold; /* Bold text for title */
    display: flex; /* Flex to arrange title and close button */
    justify-content: space-between; /* Space between title and close button */
    cursor: grab; /* Cursor style for dragging */
}

.close-button {
    background: none; /* No background for button */
    border: none; /* No border for button */
    color: white; /* Close button text color */
    font-size: 16px; /* Font size for button */
    cursor: pointer; /* Pointer cursor for button */
}

/* Window content styling */
.window-content {
    max-height: 300px; /* Set a maximum height for the content area */
    overflow-y: auto;  /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scrolling (optional) */
    padding: 10px;     /* Padding for content */
    border: 1px solid rgba(0, 0, 0, 0.3); /* Optional border for better visibility */
    background-color: rgba(255, 255, 255, 0.9); /* Optional background color */
}

/* Style for scrollbar */
.window-content::-webkit-scrollbar {
    width: 8px; /* Width of the vertical scrollbar */
}

.window-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Round edges for the scrollbar */
}

.window-content::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Background for the scrollbar track */
}

/* Styles for blog and project directories */
.window-content ul {
    list-style-type: none; /* No bullet points */
    padding: 0; /* Remove padding */
}

/* Link style for directories (blue text) */
.directory-item {
    color: blue; /* Blue text color for links */
    text-decoration: underline; /* Underline for link effect */
    cursor: pointer; /* Pointer cursor for links */
}

.directory-item:hover {
    background-color: #f0f0f0; /* Light grey background on hover */
}

/* Content style for blog and project sub-pages (black text) */
.window-content p {
    color: black; /* Black text color for content pages */
    margin: 5px 0; /* Margin for paragraphs */
}

/* Ensure all text within blog and project sub-pages is black */
.window-content .blog-content p,
.window-content .project-content p {
    color: black; /* Black text for blog and project content */
}

.project-link-icon {
    margin-left: 10px;
    margin-left: 60%;
    cursor: pointer;
    width: 15%;
    height: 15%;
  }
  